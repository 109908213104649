import WCC from 'wcc'
import { createCookie, readCookie } from '../lib/cookies'
import { toggleVisibleByCss } from '../lib/showHide'
import { renderCampusToast } from './toast'

function setCampusSlug(campusSlug: string) {
  createCookie('campus', 'watermark-' + campusSlug)
}

function setPrevCampusSlug(campusSlug: string) {
  createCookie('previous-campus', campusSlug)
}

export function getCampusSlug(campusSlug: string | null = readCookie('campus')) {
  if (!campusSlug) { return null }

  return campusSlug.replace('watermark-', '')
}

export default function campusInit() {
  $('.dropdown-item[data-campus]').on('click', function(evt) {
    const href = evt.currentTarget.getAttribute('href')
    const campusSlug = $(this).data('campus')

    if (campusSlug && campusSlug.length > 0) {
      changeCampus(campusSlug, this)
    }

    if (!href || (href == '#')) {
      evt.preventDefault()
      renderCampusToast(campusSlug)
    }
  })

  updateVisibleCampus()
}

export function changeCampus(slug: string, source: Element = document.body) {
  const previous = getCampus()
  if (previous && slug == previous.slug) {
    return
  }

  setCampusSlug(slug)
  if (previous && previous.slug.length > 0) {
    setPrevCampusSlug(previous.slug)
  }
  updateVisibleCampus(slug)
  if (!source.classList.contains('campus-undo')) {
    createCookie('render-toast', 'true')
  }
  $(source).trigger('change.campus', { slug, previous })
}

export function onChangeCampus(handler: CampusEventHandler<any>) {
  $(document).on('change.campus', handler)
}

type ICampusEvent<TElement> = JQuery.TriggeredEvent<TElement, undefined, HTMLElement, HTMLElement>
export type CampusEventHandler<TElement = any> =
  (this: TElement, t: ICampusEvent<TElement>, params: { slug: string, previous: string }) => any

declare global {
  
  interface JQuery<TElement = HTMLElement> {
    on(events: 'change.campus', handler: CampusEventHandler<TElement>): this
  }
}

export function getCampus(campusSlug: string | null = getCampusSlug()) {
  if (campusSlug && campusSlug.length > 0) {
    return WCC.activeCampuses.find((c) => c.slug == campusSlug)
  }
}

function updateVisibleCampus(campusSlug: string = getCampusSlug() || 'default') {
  toggleVisibleByCss(`[data-campus="${campusSlug}"]`, '[data-campus]')
}
