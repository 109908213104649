import { eraseCookie, readCookie } from '../lib/cookies'
import { changeCampus, getCampus, getCampusSlug } from './campus'

export default function toastInit() {
  const showToast: string | null = readCookie('render-toast')
  const currentPath = document.location.pathname.split('/').pop()

  const cookieCampus = getCampus(getCampusSlug(readCookie('campus')))
  if (cookieCampus && cookieCampus.state == 'external') {
    renderCampusToast(cookieCampus.slug)
  } else if (cookieCampus) {
    if (showToast && showToast === 'true') {
      renderCampusToast(cookieCampus.slug)
    } else if (getCampus(currentPath)) {
      if (cookieCampus.slug !== currentPath) {
        renderCampusConflictToast(cookieCampus.slug)
      }
    }
  }

  $('[data-dismiss="campus-conflict"]').on('click', (evt) => {
    evt.preventDefault()

    hideToast('campus-conflict')
  })

  $('.campus-undo').on('click', (evt) => {
    const prevCampus = evt.currentTarget.getAttribute('data-prev-campus')

    if (prevCampus && prevCampus.length > 0) {
      hideToast(`toast-${evt.currentTarget.getAttribute('data-current-campus')}`)
      changeCampus(prevCampus, evt.currentTarget)
      if (evt.currentTarget.getAttribute('data-current-page-campus-aware') === 'false') {
        evt.preventDefault()
      }
    }
  })
}

function renderCampusConflictToast(campusSlug: string) {
  const campusFromCookieElem = document.getElementById('campus-from-cookie')
  const campusSlugText = document.createTextNode(`${campusSlug}`)

  if (campusFromCookieElem) {
    campusFromCookieElem.appendChild(campusSlugText)
    renderToast('campus-conflict')
  }
}

export function renderCampusToast(
  campusSlug: string = getCampusSlug() || 'default',
  previousSlug: string | null = readCookie('previous-campus'),
) {
  const undoCampusButton: HTMLAnchorElement | null =
    document.querySelector(`.toast-${campusSlug} .toast-header .campus-undo`)

  if (previousSlug && previousSlug.length > 0) {
    hideToast(`toast-${previousSlug}`)

    if (undoCampusButton && campusSlug != previousSlug) {
      renderUndoCampusButton(undoCampusButton, previousSlug)
    }
  }

  // This event is fired when the toast has finished being hidden from the user.
  $(`.toast-${campusSlug}`).on('hidden.bs.toast', () => {
    if (undoCampusButton) {
      hideUndoCampusButton(undoCampusButton)
    }
  })

  renderToast(`toast-${campusSlug}`)
  eraseCookie('render-toast')
}

function renderToast(className: string) {
  $(`.${className}`).toast('show')
}

function hideToast(className: string) {
  $(`.${className}`).toast('hide')
}

function renderUndoCampusButton(undoCampusButton: HTMLAnchorElement, previousSlug: string) {
  undoCampusButton.setAttribute('data-prev-campus', `${previousSlug}`)
  undoCampusButton.href = document.referrer
  undoCampusButton.classList.add('show')
}

function hideUndoCampusButton(undoCampusButton: HTMLAnchorElement) {
  undoCampusButton.classList.remove('show')
}
