

export interface Stringable {
  toString(): string
}

/**
 * This is a shorthand to pass into array.map() when you want to call toString() on each item
 */
export function toString(value: Stringable): string {
  return value.toString()
}

/**
 * A single tuple from the array returned by Object.entries
 */
export type EntriesTuple<T> = [keyof T, T[keyof T]]
/**
 * The array returned by Object.entries
 */
export type EntriesArray<T> = EntriesTuple<T>[]

/**
 * Gets the entries of an object as an array of tuples, with typescript type safety
 */
export function entries<T extends { [key: string]: any }>(obj: T): EntriesArray<T> {
  return Object.entries(obj) as EntriesArray<typeof obj>
}

/**
 * Test whether the item exists (i.e. is not null or undefined).
 *
 * @example
 *   const myArr: Array<string | null | undefined> = ['a', null, undefined, 'b', false, 0]
 *   const filtered: string[] = myArr.filter(exists) // ['a', 'b', false, 0]
 */
export function exists<T>(item: T | null | undefined): item is T {
  return item !== null && item !== undefined
}

export function present<T>(value: T): value is Exclude<T, false | undefined | null | ''> {
  if (typeof value == 'string') {
    return value.length > 0
  }
  if (typeof value == 'number') {
    // 0 is considered present
    return true
  }

  return !!value
}

export function assert<T>(value: T, key?: string, msg?: string): asserts value is Exclude<T, false | undefined | null | ''> {
  if (!present(value)) {
    throw new Error(msg || 'assert failed! Value was not present')
  }
}

/**
 * Returns a new array where duplicate values have been removed
 */
export function uniq<T>(arr: T[]): T[] {
  return arr.filter((item, index) => arr.indexOf(item) === index)
}

export function uniqBy<T>(arr: T[], keyOrFn: keyof T | ((item: T) => any)): T[] {
  const fn: (item: T) => any = typeof(keyOrFn) == 'function' ?
    keyOrFn :
    (i: T) => i[keyOrFn]

  return arr.filter((item, index) => {
    const search = fn(item)
    return arr.findIndex((i) => fn(i) == search) === index
  })
}

declare global {
  
  interface Array<T> {
    /**
     * Returns a new array where duplicate values have been removed
     */
    uniq(): T[]

    /**
     * Returns a new array where duplicate values have been removed, as selected
     * by the key function
     */
    uniqBy(keyOrFn: keyof T | ((item: T) => any)): T[]
  }

  interface Window {
    refTagger?: { tag: (node: HTMLElement) => void }
  }
}

Array.prototype.uniq = function() {
  return uniq(this)
}
Array.prototype.uniqBy = function(keyOrFn) {
  return uniqBy(this, keyOrFn)
}
